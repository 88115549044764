<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <div class="text-800 mb-1"><span class="text-primary pi pi-info-circle mb-3" v-tooltip="tooltip"></span> {{ subtitle }}</div>
                <FormProcedimento @onSave="load" ref="form"></FormProcedimento>

                <Fieldset legend="Procedimentos" style="margin-top: 10px">
                    <Grid @onRemove="load" @onEdit="onEdit" ref="grid"></Grid>
                </Fieldset>
            </div>
        </div>
    </div>
</template>

<script>
import { getCurrentCompany } from '@/services/store';
import Grid from './components/Grid.vue';
import FormProcedimento from './components/FormProcedimento';

export default {
    data() {
        return {
            subtitle: 'Procedimentos do prestador',
            tooltip: 'Liberação de procediemento do prestador'
        };
    },

    components: { Grid, FormProcedimento },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar prestador procedimento' : 'Adicionar prestador procedimento';
        },
        tenant() {
            return getCurrentCompany();
        }
    },
    watch: {
        tenant() {
            this.load();
        }
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        },
        onEdit(record) {
            const dto = {
                ...record,
                valores: record.prestadorValor ?? {}
            };
            this.$refs.form.loadData(dto);
        }
    }
};
</script>