<template>
    <Fieldset :legend="form.id ? 'Alteração - Prestador: ' + prestador.name : 'Inclusão - Prestador: ' + prestador.name">
        <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-6">
                <label for="procedimento">Procedimento</label>
                <DropdownExame
                    id="procedimento"
                    v-model="form.procedimento"
                    :disabled="!!form.id"
                    @update:model-value="resgatarDiasAntecedencia"
                ></DropdownExame>
            </div>

            <div class="field col-12 md:col-3">
                <label for="valorPagar">Valor a pagar</label>
                <InputNumber :min="0" id="valorPagar" v-model="form.valores.valorPagar" mode="currency" currency="BRL"></InputNumber>
            </div>

            <div class="field col-12 md:col-3">
                <label for="valorCobrar">Valor a cobrar</label>
                <InputNumber :min="0" id="valorCobrar" v-model="form.valores.valorCobrar" mode="currency" currency="BRL"></InputNumber>
            </div>
        </div>

        <div class="p-fluid formgrid grid">
            <div v-if="form.procedimento" class="field col-12 md:col-4">
                <label for="diasAntecedencia">Dias de antecedência em relação ao exame clínico</label>
                <InputNumber :min="0" id="diasAntecedencia" v-model="form.diasAntecedencia" :disabled="form.herdarDiasAntecedencia" />
            </div>
            <div v-if="form.procedimento" class="field col-12 md:col-4 flex m-0 align-items-center">
                <Checkbox v-model="form.herdarDiasAntecedencia" inputId="herdarDiasAntecedencia" :binary="true" @input="resgatarDiasAntecedencia" />
                <label for="herdarDiasAntecedencia" class="m-0 ml-2"> Utilizar padrão do exame </label>
            </div>
            <div class="field col-12 md:col-fixed flex align-items-center mt-3">
                <label for="binary" class="m-0 mr-2">Por hora marcada</label>
                <InputSwitch v-model="form.atendeHoraMarcada" />
            </div>
        </div>
        <div class="flex justify-content-end gap-4">
            <Button
                class="p-button-secondary"
                style="padding: 8px 12px; min-width: 150px"
                v-if="form.id"
                v-tooltip="'Cancelar'"
                label="Cancelar"
                @click="cancel"
            />
            <Button
                outlined
                class="p-button-primary"
                style="padding: 8px 12px; min-width: 150px"
                :disabled="!form.procedimento"
                v-tooltip="'Salvar'"
                @click="save"
                :loading="loadingSave"
                label="Salvar"
            />
        </div>
    </Fieldset>
</template>
<script>
import DropdownExame from '../../exames/components/DropdownExame';
import BaseService from '../../../services/BaseService';

export default {
    components: { DropdownExame },
    emits: ['onSave'],
    data() {
        return {
            loadingSave: false,
            form: {
                valores: {},
                atendeHoraMarcada: undefined,
                diasAntecedencia: undefined,
                herdarDiasAntecedencia: true
            },
            prestador: {}
        };
    },
    async created() {
        this.$service = new BaseService('/prestador-exames');
        this.$servicePrestador = new BaseService('/suppliers');
        await this.carregarPrestador();
    },
    watch: {
        'form.diasAntecedencia': {
            handler(val) {
                if (!val) {
                    this.form.diasAntecedencia = 0;
                }
            },
            imediate: true
        }
    },
    methods: {
        async save() {
            try {
                this.loadingSave = true;
                const idPrestador = this.$route.params.id;
                const idProcedimento = this.form.procedimento.id;
                if (!this.form.diasAntecedencia) this.form.diasAntecedencia = 0;
                const dto = {
                    ...this.form,
                    procedimento: {
                        idPrestador,
                        idProcedimento
                    }
                };
                const saved = await this.$service.save(dto);
                this.form = {
                    valores: {}
                };
                this.$emit('onSave', saved);
                this.loadingSave = false;
                this.$toast.add({ severity: 'success', summary: 'Sucesso', detail: 'Salvo com sucesso', life: 5000 });
            } catch (error) {
                this.loadingSave = false;
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: error.response.data.message, life: 5000 });
            }
        },
        async carregarPrestador() {
            const { data } = await this.$servicePrestador.findById(this.$route.params.id);
            this.prestador = data;
        },
        async resgatarDiasAntecedencia() {
            this.loadingSave = true;
            if (this.form.procedimento) {
                this.form.diasAntecedencia = this.form.procedimento.diasAntecedencia;
            }
            this.loadingSave = false;
        },
        cancel() {
            this.form = {
                valores: {}
            };
        },
        loadData(form) {
            this.form = JSON.parse(JSON.stringify(form));
        }
    }
};
</script>
