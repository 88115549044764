<template>
    <div class="flex flex-column mb-3 md:flex-row md:justify-content-between md:align-items-center">
        <div class="p-fluid mt-2 w-full md:mt-0 md:w-5">
            <Chips v-model="filter" placeholder="Pesquisar" @add="load" @remove="load" />
        </div>
    </div>

    <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />

    <DataTable dataKey="id" :value="records" :row-hover="true" class="p-datatable-sm" responsiveLayout="stack" breakpoint="640px" @sort="onSort">
        <template #empty> Nenhum registro encontrado. </template>

        <template #loading> Carregando registros. Aguarde ... </template>

        <Column bodyClass="text-left" headerStyle="width: 5rem;">
            <template #body="slotProps">
                <Button icon="pi pi-pencil" class="p-button-text p-button-info" v-tooltip="'Editar'" @click="onClickEditRecord(slotProps.data)" />
            </template>
        </Column>

        <Column :sortable="true" field="procedimento.name" header="Procedimento">
            <template #body="slotProps">
                {{ slotProps.data.procedimento.name }}
                <i
                    v-if="slotProps.data.statusSincronizacao == 'ERRO'"
                    class="pi pi-info-circle"
                    v-tooltip="slotProps.data.mensagemErro"
                    style="color: red; cursor: pointer"
                    @Click="openRetryDialog(slotProps.data)"
                />
            </template>
        </Column>

        <Column field="atendeHoraMarcada" header="Atende por hora marcada">
            <template #body="slotProps">
                <Checkbox :binary="true" disabled :modelValue="slotProps?.data?.atendeHoraMarcada" />
            </template>
        </Column>

        <Column field="diasAntecedencia" header="Dias de antecedência" />

        <Column field="prestadorValor.valorPagar" header="Valor a pagar">
            <template #body="slotProps">
                {{ $filters.formatCurrency(slotProps?.data?.prestadorValor?.valorPagar ?? 0) }}
            </template>
        </Column>

        <Column field="prestadorValor.valorCobrar" header="Valor a cobrar">
            <template #body="slotProps">
                {{ $filters.formatCurrency(slotProps?.data?.prestadorValor?.valorCobrar ?? 0) }}
            </template>
        </Column>

        <Column bodyClass="text-right" headerStyle="width: 10rem;">
            <template #body="slotProps">
                <Button
                    icon="pi pi-trash"
                    class="p-button-text p-button-danger"
                    v-tooltip="'Excluir'"
                    @click="onClickConfirmDeleteRecord(slotProps.data)"
                />
            </template>
        </Column>
    </DataTable>
    <Paginator
        :rows="perPage"
        :totalRecords="total"
        v-model:first="page"
        @page="onPage($event)"
        template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
        currentPageReportTemplate="Página {currentPage} de {totalPages}"
    >
    </Paginator>

    <AppRetryDialog
        v-if="showRetryDialog"
        v-model:visible="showRetryDialog"
        :loading="loadingLiberacao"
        :title="liberacaoPendente.mensagemErro"
        @onRetry="reenviarLiberacao(this.records)"
        @onClose="showRetryDialog = false"
    />

    <AppDeleteDialog
        v-if="showAppDeleteDialog"
        v-model:visible="showAppDeleteDialog"
        :loadingRemove="loadingRemove"
        @onConfirm="onClickDeleteRecord"
        @onClose="onClickCloseDeleteRecord"
    >
    </AppDeleteDialog>
</template>
<script>
import BaseService from '../../../services/BaseService';
import { getClientBase } from '@/services/http';

export default {
    emits: ['onRemove', 'onEdit'],
    mounted() {
        this.$service = new BaseService('/prestador-exames');
        this.load();
    },
    data() {
        return {
            record: {},
            records: [],
            filter: [],
            page: 1,
            total: 0,
            loading: false,
            totalPage: 0,
            loadingLiberacao: false,
            liberacaoPendente: {},
            showRetryDialog: false,
            perPage: 10,
            showAppDeleteDialog: false,
            loadingRemove: false,
            sort: null
        };
    },
    methods: {
        async load() {
            this.loading = true;
            await this.loadData();
            this.loading = false;
        },
        async loadData(page = 1) {
            try {
                const path = `/prestador-exames/find-by-prestador/${this.$route.params.id}`;
                const { data } = await getClientBase().get(path, {
                    params: {
                        limit: this.perPage,
                        page: page ?? this.page,
                        filter: this.filter,
                        sort: this.sort
                    }
                });
                this.records = data.items;
                this.total = data.meta?.totalItems;
                this.totalPage = data.meta?.totalPages;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: error.response.data.message, life: 5000 });
            }
        },
        async onPage(event) {
            this.loadData(event.page + 1);
        },
        openRetryDialog(data) {
            this.liberacaoPendente = data;
            this.showRetryDialog = true;
        },
        async reenviarLiberacao() {
            try {
                this.loadingLiberacao = true;
                await getClientBase().patch(`/prestador-exames/atualiza-soc/${this.liberacaoPendente.idPrestador}/${this.liberacaoPendente.id}`);
                this.loadingLiberacao = false;
                this.showRetryDialog = false;
                this.$toast.add({ severity: 'success', summary: 'Liberação realizada com sucesso', life: 5000 });
                await this.load();
            } catch (err) {
                console.error(err);
                const message = err?.response?.data?.message || err.message;
                this.$toast.add({ severity: 'error', summary: 'Erro ao realizar liberação, favor verificar', detail: message, life: 5000 });
            }
        },
        onClickEditRecord(record) {
            this.$emit('onEdit', record);
        },
        onClickConfirmDeleteRecord(record) {
            this.record = record;
            this.showAppDeleteDialog = true;
        },
        onClickCloseDeleteRecord() {
            this.showAppDeleteDialog = false;
        },
        async excluir(record) {
            try {
                this.loadingRemove = true;
                await this.$service.remove(record.id);
                this.$emit('onRemove', record);
                this.$toast.add({ severity: 'success', summary: 'Registro excluído com sucesso!', life: 3000 });
                this.loadingRemove = false;
            } catch (err) {
                const message = err?.response?.data?.message || err.message;
                this.$toast.add({ severity: 'error', summary: 'Erro ao realizar liberação, favor verificar', detail: message, life: 5000 });
                this.loadingRemove = false;
                await this.load();
            }
        },
        async onClickDeleteRecord() {
            if (!this.record) {
                return;
            }

            await this.excluir(this.record);
            this.showAppDeleteDialog = false;
        },
        onSort(event) {
            this.currentSortField = event.sortField;
            this.currentSortOrder = event.sortOrder;
            const field = event.sortField
                ?.split(/(?=[A-Z])/)
                .join('_')
                .toLowerCase()
                ? `${event.sortField
                      ?.split(/(?=[A-Z])/)
                      .join('_')
                      .toLowerCase()}`
                : '';
            const order = event.sortOrder == 1 ? `ASC` : 'DESC';
            if (field) {
                this.sort = {
                    [field]: order
                };
            } else {
                this.sort = null;
            }

            this.load();
        }
    }
};
</script>
